<template>
    <div id="PathPlanning">
        <div style="position: relative;">
            <div id="container">
            </div>
            <div style="position: absolute; left: 30px;top: 30px; padding: 10px;background-color: #fff;">
                <el-radio v-model="radio" :label="1">设置终点</el-radio>
                <el-radio v-model="radio" :label="2">设置途径点</el-radio>
                <el-button type="warning" @click="GenerateTrack">生成行驶路线</el-button>
                <el-button type="danger">提交行驶路线</el-button>
            </div>
        </div>

    </div>
</template>

<script>
import AMapLoader from '@amap/amap-jsapi-loader'
import lajiche from "../assets/lajiche1.png"
import zhongdian from "../assets/zhongdian.png"
window._AMapSecurityConfig = {
    securityJsCode: '380be16e67ca78c3725692b21427d46d' // 开发者密钥
}
export default {
    name: 'DemoPathPlanning',

    data() {
        return {
            map: null,
            lng: "",
            lat: "",
            radio: 1,
            marker: null,
            endCoordinate: [],//终点坐标
            route: null,//行驶路线的实例
        };
    },
    mounted() {
        this.lng = this.$route.query.lng
        this.lat = this.$route.query.lat
        this.initMap()
    },
    methods: {
        initMap() {
            AMapLoader.load({
                key: "b7c4488941d2e660802ee0ac4e010df2",             //开发者Key
                version: "2.0",      // 指定要加载的 JSAPI 的版本，缺省时默认为 1.4.15
                plugins: ['AMap.Driving', "AMap.LngLat"],       // 需要使用的的插件列表
            }).then((AMap) => {
                this.map = new AMap.Map("container", {  //设置地图容器id
                    viewMode: "3D",    //是否为3D地图模式
                    zoom: 11,           //初始化地图级别
                    center: [this.lng, this.lat], //初始化地图中心点位置
                });

                let marker = new AMap.Marker({
                    icon: new AMap.Icon({
                        image: lajiche,
                        imageSize: new AMap.Size(50, 25)
                    }),
                    position: [this.lng, this.lat],
                    draggable: false,
                })
                this.map.add(marker)
                this.map.on("click", (e) => {
                    if (this.radio == 1) {
                        if (this.marker) {
                            this.marker.remove()
                        }
                        this.endCoordinate = [e.lnglat.lng, e.lnglat.lat]
                        this.marker = new AMap.Marker({
                            icon: new AMap.Icon({
                                image: zhongdian,
                                imageSize: new AMap.Size(26, 36)
                            }),
                            position: [e.lnglat.lng, e.lnglat.lat],
                            draggable: false,
                            offset: [-10, -30]
                        })
                        this.map.add(this.marker)
                    } else {

                    }
                    console.log(e);
                })

            }).catch(e => {
                console.log(e);
            })
        },
        GenerateTrack() {
            if (!this.route) {
                this.route = new AMap.Driving({
                    map: this.map,
                    // policy: 0
                })
            }

            this.route.search(new AMap.LngLat(this.lng, this.lat), new AMap.LngLat(this.endCoordinate[0], this.endCoordinate[1]), function (status, data) {
                console.log(data);
            })

        }
    },
};
</script>

<style lang="less" scoped>
#PathPlanning {
    width: 100vw;
    height: 100vh;
}

#container {
    width: 100vw;
    height: 100vh;
}
</style>